import SEO from '@components/seo';
import imageUrlFor from '@helpers/imageUrlFor';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

const Lookbook = () => {
  const lookbookData = useStaticQuery(
    graphql`
      query {
        allSanityLookbook {
          edges {
            node {
              metaDescription
              metaImage {
                asset {
                  _id
                }
              }
              title
              heading
              lookbookUrl
            }
          }
        }
      }
    `,
  );

  const lookbookNode = lookbookData.allSanityLookbook.edges[0].node;
  return (
    <div>
      <SEO
        title={lookbookNode?.title || null}
        description={
          lookbookNode?.metaDescription ? lookbookNode.metaDescription : null
        }
        image={
          lookbookNode?.metaImage?.asset
            ? imageUrlFor(lookbookNode.metaImage).url()
            : null
        }
      />
      <div className="wrapper">
        <h1 className="type-sans-830 xl:type-sans-930 mb-8">
          {lookbookNode.heading}
        </h1>
        <iframe
          title={lookbookNode.title}
          src={lookbookNode.lookbookUrl}
          allowFullScreen={true}
          className="w-full aspect-[65/46]"
        />
      </div>
    </div>
  );
};

export default Lookbook;
